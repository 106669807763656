// import axios from "axios";

async function fetchToken() {
  return new Promise((resolve) =>
    resolve(
      "BQAqAoND7BooIcoGkqLchurWgIRP5TZ_STeeqJopz8vDO7U6rduGR6_J7fmX1TDEx1tqBPZ0hNYuI41qH5Q"
    )
  );
}

export default class TrackAPI {
  constructor() {
    this.token;
    this.intents = 3;
  }

  async auth() {
    try {
      const response = await fetch("/.netlify/functions/token");
      const data = await response.json();
      this.token = data.token;
    } catch (error) {
      this.token = await fetchToken();
    }
  }

  async getToken() {
    if (!this.token) {
      await this.auth();
    }
    return this.token;
  }

  async searchTrack(query) {
    const data = await this.fetch(query);

    return data.map((item) => ({
      id: item.id,
      name: item.name,
      artist: item.artists[0].name,
      artwork: item.album.images.pop().url,
      previewUrl: item.previewUrl,
    }));
  }

  async fetch(query) {
    const token = await this.getToken();

    if (query.length < 3) return [];

    const url = new URL("https://api.spotify.com/v1/search");

    const params = {
      q: query,
      type: "track",
      market: "ES",
      limit: 5,
      include_external: "audio",
    };

    url.search = new URLSearchParams(params);
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      if (this.intents <= 0) return [];
      this.intents--;
      await this.auth();
      return this.fetch(query);
    }

    const data = await response.json();
    return data?.tracks?.items ?? [];
  }

  async getPreview(track) {
    if (track && !track.previewUrl) {
      track.previewUrl = await this.fetchItunes(
        `${track.artist} ${track.name}`
      );
    }
    return track?.previewUrl;
  }

  async fetchItunes(query) {
    const response = await fetch(`/.netlify/functions/preview?query=${query}`);
    const data = await response.json();
    // console.log("preview", data);
    return data?.preview;
  }
}
