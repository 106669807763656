import React from "react";
import Floor from "../../atoms/Floor";
import "./VideoFloor.scss";

const videoUrl = new URL("/src/assets/elfollo.mp4", import.meta.url);

class VideoFloor extends React.Component {
  constructor({ props }) {
    super();
  }

  componentDidMount() {}

  render() {
    return (
      <Floor className="VideoFloor">
        <video
          className="video"
          autoPlay
          loop
          muted
          playsInline
          src={videoUrl}
        ></video>
        <div className="content flow">
          <h2>El Folló.</h2>
          <p>
            Si si, ho has llegit bé, ens casem a la masia El Folló. Una masia
            atípica però molt especial i, fent honor al seu nom, ¡volem
            muntar-ne una de grossa! 😜
          </p>
        </div>
      </Floor>
    );
  }
}
export default VideoFloor;
