import React from "react";

import "./Floor.scss";

class Floor extends React.Component {
  constructor(props) {
    super(props);
    this.refs = {};
  }

  mount(root) {
    // console.log(root,this.props)
    this.props.forwardRef && this.props.forwardRef(root);
  }

  componentDidMount() {
    // console.log(this.refs.root);
  }

  render() {
    const { children, className } = this.props;

    return (
      <section className={`Floor ${className}`} ref={(el) => this.mount(el)}>
        {children}
      </section>
    );
  }
}
export default Floor;
