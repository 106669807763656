import React from "react";
import Floor from "../../atoms/Floor";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import "./LocationFloor.scss";

import Route from "./Route/Route";
const videoUrl = new URL("/src/assets/elfollo.mp4", import.meta.url);
const mapsUrl = "https://goo.gl/maps/1hqYLoaLnhFjDebT7";

class LocationFloor extends React.Component {
  constructor(props) {
    super(props);
    this.dom = {};
  }

  componentDidMount() {
    // console.log("00", this.dom.root);

    const offset = 15;
    gsap.set(this.dom.video, { scale: 1 + offset * 0.02 });
    gsap
      .timeline({
        ease: "none",
        scrollTrigger: {
          trigger: this.dom.videoWrap,
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      })
      .add([
        gsap.fromTo(
          [this.dom.video],
          { yPercent: -offset },
          { yPercent: offset, ease: "Power4.easeInOut", duration: 2 }
        ),
      ]);
  }

  render() {
    return (
      <Floor
        className="LocationFloor flow"
        forwardRef={(root) => (this.dom.root = root)}
      >
        <div className="video" ref={(el) => (this.dom.videoWrap = el)}>
          <video
            autoPlay
            loop
            muted
            playsInline
            src={videoUrl}
            ref={(el) => (this.dom.video = el)}
          ></video>
        </div>

        <div className="flow">
          <h2>El Folló.</h2>
          <p>
            Si si, ho has llegit bé, ens casem a la masia El Folló. Una masia
            atípica però molt especial i, fent honor al seu nom, ¡volem
            muntar-ne una de grossa! 😜
          </p>
          <p>
            Tot, la ceremònia (que serà curteta 😜), l’aperitiu, el sopar 🤤, i
            la festa 💃, tindrà lloc al Folló, una masia situada a Tagamanent, i
            a 30min. de Sabadell i Vilanova. 😍
          </p>
        </div>

        <Route></Route>

        <div>
          <a href={mapsUrl} className="btn" target="_blank">
            Obre a Google Maps
          </a>
        </div>
      </Floor>
    );
  }
}
export default LocationFloor;
