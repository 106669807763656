import React, { useState, useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import "./styles/main.scss";

import IntroFloor from "./floors/IntroFloor/IntroFloor";
import VideoFloor from "./floors/VideoFloor/VideoFloor";
import LocationFloor from "./floors/LocationFloor/LocationFloor";
import DateFloor from "./floors/DateFloor/DateFloor";
import ScheduleFloor from "./floors/ScheduleFloor/ScheduleFloor";
import FormFloor from "./floors/FormFloor/FormFloor";
import Footer from "./floors/Footer/Footer";

export function App() {
  document.body.classList.remove("loaded");

  return (
    <>
      <IntroFloor></IntroFloor>
      <LocationFloor></LocationFloor>
      <DateFloor></DateFloor>
      <ScheduleFloor></ScheduleFloor>
      <FormFloor></FormFloor>
      <Footer></Footer>
    </>
  );
}
