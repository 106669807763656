import React from "react";
import "./Form.scss";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.dom = {};

    this.state = {
      submitting: false,
      submitted: false,
    };
  }

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();

    const duration = 2;

    this.dom.form.classList.add("submitting");
    this.setState({ submitting: true });

    gsap.timeline({}).add([
      gsap.to(this.dom.body, {
        autoAlpha: 0,
        duration: duration / 2,
        ease: "power4",
      }),
      gsap.to(this.dom.foot, { top: "50%", duration, ease: "power4" }),
      gsap.to(this.dom.form, { height: "50rem", duration, ease: "power4" }),
      gsap.to(window, {
        scrollTo: this.dom.anchor,
        ease: "power3",
        duration,
        overwrite: true,

        onComplete: () => {
          const form = e.target;
          let formData = new FormData(form);

          const dump = {};
          for (var pair of formData.entries()) {
            dump[pair[0]] = pair[1];
          }
          console.log(form, dump);

          const resolveOk = () => {
            this.setState({ submitting: false, submitted: true });
            this.dom.splashThanks.classList.add("active");
            console.log("Form successfully submitted");
          };
          const resolveKo = (error) => {
            this.dom.splashError.classList.add("active");
            console.log(error);
          };

          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
          })
            .then((response) => {
              if (response.status === 200) {
                resolveOk();
              } else {
                resolveKo({
                  status: response.status,
                  statusText: response.statusText,
                });
              }
            })
            .catch(resolveKo)
            .finally(() => {
              this.setState({ submitting: false, submitted: true });
              this.dom.form.classList.add("submitted");
            });
        },
      }),
    ]);
  };

  render() {
    const { children, nbAssistants = 1 } = this.props;
    const { submitting, submitted } = this.state;

    return (
      <>
        <form
          className="Form"
          onSubmit={this.handleSubmit}
          ref={(el) => (this.dom.form = el)}
        >
          <div
            className="Form__anchor"
            ref={(el) => (this.dom.anchor = el)}
          ></div>
          <div className="Form__body" ref={(el) => (this.dom.body = el)}>
            {children}
          </div>
          <div className="Form__foot" ref={(el) => (this.dom.foot = el)}>
            <button className="btn btn-submit">
              <span> {nbAssistants > 1 ? "Apunta’ns" : "Apunta'm"}</span>
              <svg viewBox="25 25 50 50">
                <circle
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
              </svg>
            </button>
          </div>
          <div
            className="Form__splah Form__splah--thanks"
            ref={(el) => (this.dom.splashThanks = el)}
          >
            <h4>Assistència confirmada 😁</h4>
            <h3>{nbAssistants > 1 ? "Us esperem!" : "T'esperem!"}</h3>
          </div>
          <div
            className="Form__splah Form__splah--thanks"
            ref={(el) => (this.dom.splashError = el)}
          >
            <h4>Uups... hi ha hagut algun error 😅</h4>
            <p>
              Si us plau, prova en una estona.
              <br />
              Si segueix sense funcionar, avisa'ns!
            </p>
          </div>
        </form>

        {/* <button
          type="button"
          onClick={() => this.setState({ submitting: !this.state.submitting })}
        >
          toggle submitting
        </button>

        <button
          type="button"
          onClick={() => this.setState({ submitted: !this.state.submitted })}
        >
          toggle submitted
        </button>

        <button type="button" onClick={() => this.tmpSubmit()}>
          submit
        </button>
        <button type="button" onClick={() => this.resolve()}>
          resolve
        </button> */}
      </>
    );
  }
}
export default Form;
