import React from "react";
import Floor from "../../atoms/Floor";
import "./Footer.scss";

import faceAnna from "../IntroFloor/Faces/anna.png";
import faceGerard from "../IntroFloor/Faces/gerard.png";

class Footer extends React.Component {
  constructor({ props }) {
    super();
  }

  componentDidMount() {}

  render() {
    return (
      <Floor className="Footer">
        <div className="Footer__head">
          <h3>Save the date</h3>
          <time dateTime="2022-07-02T18:00:00+0000">02/07/2022</time>
        </div>
        <div className="Footer__info">
          <div className="card">
            <img src={faceGerard} alt="Gerard Pastor" />
            <div>
              <h4>Gerard:</h4>
              <a href="tel:0034607819919">
                <span>📱</span> 607 819 919
              </a>
              <br />
              <a href="mailto:&#103;&#101;&#114;&#097;&#114;&#100;&#112;&#097;&#115;&#116;&#111;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">
                <span>✉️</span>{" "}
                &#103;&#101;&#114;&#097;&#114;&#100;&#112;&#097;&#115;&#116;&#111;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
              </a>
            </div>
          </div>
          <div className="card">
            <img src={faceAnna} alt="Anna Rovira" />
            <div>
              <h4>Anna:</h4>
              <a href="tel:0034692605270">
                <span>📱</span> 692 605 270
              </a>
              <br />
              <a href="mailto:&#097;&#110;&#110;&#097;&#046;&#114;&#111;&#118;&#105;&#114;&#097;&#064;&#111;&#117;&#116;&#108;&#111;&#111;&#107;&#046;&#099;&#111;&#109;">
                <span>✉️</span>{" "}
                &#097;&#110;&#110;&#097;&#046;&#114;&#111;&#118;&#105;&#114;&#097;&#064;&#111;&#117;&#116;&#108;&#111;&#111;&#107;&#046;&#099;&#111;&#109;
              </a>
            </div>
          </div>
        </div>
        <div className="Footer__foot">
          Developed with ❤️ by Anna &amp; Gerard
        </div>
      </Floor>
    );
  }
}
export default Footer;
