import React from "react";
import Floor from "../../atoms/Floor";
import "./FormFloor.scss";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

import TrackInput from "./Track/TrackInput";
import Form from "./Form";

const MAX_ASSISTANS = 4;

class FormFloor extends React.Component {
  constructor({ props }) {
    super();
    document.body.classList.add("loaded");

    this.form;

    this.state = {
      submitting: false,
      submitted: false,
      nbAssistants: 1,
      track_query: "",
      track_id: "",
      track_name: "",
      track_artist: "",
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    const { name, value } = e.target ?? {};
    name && this.setState({ [name]: value });
    // console.log("change", name, value);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target, this.state);

    this.setState({ submitting: true });

    const form = e.target;
    let formData = new FormData(form);

    const dump = {};
    for (var pair of formData.entries()) {
      dump[pair[0]] = pair[1];
    }
    console.log(form, dump);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => console.log("Form successfully submitted"))
      .catch((error) => alert(error))
      .finally(() => this.setState({ submitting: false }));
  };

  addAssistant = () => {
    this.setState({
      nbAssistants: Math.min(MAX_ASSISTANS, this.state.nbAssistants + 1),
    });
  };
  removeAssistant = () => {
    const nbAssistants = Math.max(1, this.state.nbAssistants - 1);
    this.setState({
      nbAssistants,
      assistant2: nbAssistants >= 2 ? this.state.assistant2 : null,
      assistant3: nbAssistants >= 3 ? this.state.assistant3 : null,
      assistant4: nbAssistants >= 4 ? this.state.assistant4 : null,
    });
  };

  handleChangeTrack = ({ query }) => {
    this.setState({ track_query: query });
  };

  handleSelectTrack = ({ track }) => {
    this.setState({
      track_id: track?.id,
      track_name: track?.name,
      track_artist: track?.artist,
    });
  };

  tmpSubmit() {
    const submitting = !this.state.submitting;
    if (submitting) {
      const rect = this.form.getBoundingClientRect();
      console.log(this.form, rect, this.form.offsetTop);
      gsap.to(window, { scrollTo: this.form, ease: "power4", overwrite: true });
    }
    this.setState({ submitting });
  }

  resolve() {
    gsap.to(window, {
      scrollTo: this.form,
      ease: "power4",
      overwrite: true,
      onComplete: () => {
        this.setState({ submitted: true });
      },
    });
  }

  render() {
    const { nbAssistants, submitting, submitted } = this.state;

    return (
      <Floor className="FormFloor flow">
        <div className="flow">
          <h2>T’apuntes?</h2>
          <p>
            Pots confirmar la teva asistència emplenant el formulari següent i
            nosaltres ens encarreguem d’organitzar la resta.
          </p>
        </div>

        <Form nbAssistants={nbAssistants}>
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="nbAssistants" value={nbAssistants} />

          <fieldset>
            <div className="fieldset-head">
              <legend>Qui ets/sou?</legend>

              <button
                type="button"
                className="btn"
                onClick={this.addAssistant}
                disabled={nbAssistants >= MAX_ASSISTANS}
              >
                +
              </button>
              <button
                type="button"
                className="btn"
                onClick={this.removeAssistant}
                disabled={nbAssistants <= 1}
              >
                -
              </button>
            </div>
            {[...Array(nbAssistants)].map((e, i) => (
              <div className="list-item" key={i}>
                <span>{i + 1}.</span>
                <input
                  name={`assistant${i + 1}`}
                  type="text"
                  required
                  autoComplete="off"
                  placeholder="Nom i cognoms"
                />
              </div>
            ))}
          </fieldset>
          <fieldset>
            <legend>
              {nbAssistants > 1
                ? "Com contactem amb vosaltres"
                : "Com contactem amb tu"}
              ?
            </legend>
            <input
              type="email"
              name="email"
              required
              autoComplete="email"
              placeholder={
                nbAssistants > 1
                  ? "Indiqueu-nos un mail de contacte"
                  : "Indica’ns un mail de contacte"
              }
            />
            <input
              type="text"
              name="phone"
              required
              autoComplete="tel"
              placeholder={
                nbAssistants > 1
                  ? "Indiqueu-nos un telèfon de contacte"
                  : "Indica’ns un telèfon de contacte"
              }
            />
          </fieldset>
          <fieldset>
            <legend>
              {nbAssistants > 1
                ? "Digueu-nos una cançó que us agradaria que sonés:"
                : "Diga’ns una cançó que t’agradaria que sonés:"}
            </legend>
            <div>
              <TrackInput
                name="track_query"
                onChange={this.handleChangeTrack}
                onSelect={this.handleSelectTrack}
                placeholder={
                  nbAssistants > 1
                    ? "Busqueu aquí la cançó"
                    : "Busca aquí la cançó"
                }
              ></TrackInput>

              <input
                type="hidden"
                name="track_query"
                value={this.state.track_query}
              />
              <input
                type="hidden"
                name="track_id"
                value={this.state.track_id}
              />
              <input
                type="hidden"
                name="track_name"
                value={this.state.track_name}
              />
              <input
                type="hidden"
                name="track_artist"
                value={this.state.track_artist}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>I per finalitzar:</legend>
            <input
              type="text"
              name="allergies"
              autoComplete="off"
              placeholder={
                nbAssistants > 1
                  ? "Algú de vosaltres té alguna alèrgia?"
                  : "Tens alguna alèrgia?"
              }
            />
            <textarea
              name="comments"
              placeholder="Algún comentari?"
              autoComplete="off"
              rows="3"
            ></textarea>
          </fieldset>
        </Form>
      </Floor>
    );
  }
}
export default FormFloor;
