import React from "react";
import { debounce } from "lodash";

import "./TrackInput.scss";

import TrackApi from "./TrackApi";

class TrackInput extends React.Component {
  constructor(props) {
    super();

    this.api = new TrackApi();

    // this.onChange = props.onChange || (() => {});
    // this.onSelect = props.onSelect || (() => {});

    this.state = {
      query: "",
      tracks: [],
      previews: {},
    };
  }

  handleInput = async (e) => {
    const query = e.target.value;
    this.setState({ query });
    this.searchTrack(query.trim());
  };

  searchTrack = async (query) => {
    this.props.onChange && this.props.onChange({ query });

    if (query.length < 3) {
      return this.setState({ tracks: [] });
    }

    const tracks = await this.api.searchTrack(query);
    this.setState({ tracks });
  };
  // searchTrack = debounce(async (query) => {
  //   this.props.onChange && this.props.onChange({ query });

  //   if (query.length < 3) {
  //     return this.setState({ tracks: [] });
  //   }

  //   const tracks = await this.api.searchTrack(query);
  //   this.setState({ tracks });
  // }, 300);

  selectTrack = async (track) => {
    this.setState({ track, previewUrl: "" });
    this.props.onSelect && this.props.onSelect({ track });
    const previewUrl = await this.api.getPreview(track);
    this.setState({
      previews: { ...this.state.previews, [track.id]: previewUrl },
    });
  };

  // async getPreview(track) {
  //   if (track.id in this.state.previews) return this.state.previews[track.id];

  //   const previewUrl = await this.api.getPreview(track);
  //   this.setState({
  //     previewUrl,
  //     previews: { ...previews, [track.id]: previewUrl },
  //   });
  // }

  renderTrack() {
    const { track, previews } = this.state;

    return (
      <>
        <div className="TrackInput__track Track">
          <div className="Track__artwork">
            {track.artwork && <img src={track.artwork} />}
          </div>
          <div className="Track__info">
            <h3>{track.name}</h3>
            <span>{track.artist}</span>
          </div>

          <a
            className="TrackInput__remove"
            onClick={() => this.selectTrack(null)}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.833 29.667 29.167 6.333M5.833 6.333l23.334 23.334"
                stroke="#212529"
                strokeWidth="2"
              />
            </svg>
          </a>
        </div>
        {track.id in previews && (
          <div>
            <audio src={previews[track.id]} controls></audio>
          </div>
        )}
      </>
    );
  }

  renderDropdown() {
    const { tracks } = this.state;

    if (!tracks.length) return "";

    return (
      <div className="TrackInput__tracks">
        {tracks.map((track, i) => (
          <button
            className="Track"
            key={i}
            onClick={() => this.selectTrack(track)}
          >
            <div className="Track__artwork">
              {track.artwork && <img src={track.artwork} />}
            </div>
            <div className="Track__info">
              <h3>{track.name}</h3>
              <span>{track.artist}</span>
            </div>
          </button>
        ))}
      </div>
    );
  }
  renderForm() {
    const { placeholder, name } = this.props;
    const { query } = this.state;

    return (
      <div className="TrackInput__input">
        <input
          name={name}
          onChange={this.handleInput}
          placeholder={placeholder}
          value={query}
          autoComplete="off"
        />
        {this.renderDropdown()}
      </div>
    );
  }

  render() {
    const { track } = this.state;

    return (
      <div className="TrackInput">
        {track ? this.renderTrack() : this.renderForm()}
      </div>
    );
  }
}
export default TrackInput;
