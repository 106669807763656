import React from "react";
import Floor from "../../atoms/Floor";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import "./ScheduleFloor.scss";

const glyphs = [
  new URL("../../assets/glyphs/glyph01.svg", import.meta.url),
  new URL("../../assets/glyphs/glyph02.svg", import.meta.url),
  new URL("../../assets/glyphs/glyph03.svg", import.meta.url),
  new URL("../../assets/glyphs/glyph04.svg", import.meta.url),
  new URL("../../assets/glyphs/glyph05.svg", import.meta.url),
];

class ScheduleFloor extends React.Component {
  constructor(props) {
    super(props);
    this.dom = { glyphs: [], labels: [] };
  }

  componentDidMount() {
    gsap
      .timeline({
        ease: "linear",
        scrollTrigger: {
          trigger: this.dom.schedule,
          start: "0% 80%",
          end: "100% 90%",
          scrub: 0.3,
          invalidateOnRefresh: true,
          // markers: true,
        },
      })
      .add([
        gsap.fromTo(
          this.dom.schedule,
          { backgroundSize: ".3rem 0%" },
          { backgroundSize: ".3rem 100%", duration: 0.8, ease: "linear" }
        ),
        gsap.fromTo(
          this.dom.glyphs,
          { autoAlpha: 0, scale: 0 },
          { autoAlpha: 1, scale: 1, stagger: 0.2, duration: 0.2 }
        ),
        gsap.fromTo(
          this.dom.labels,
          { autoAlpha: 0, xPercent: -20 },
          { autoAlpha: 1, xPercent: 0, stagger: 0.2, duration: 0.2 }
        ),
      ]);
  }

  render() {
    const schedule = [
      { glyph: glyphs[0], title: "Arribada & Refrigeri", datetime: "18:00" },
      { glyph: glyphs[1], title: "Cerimònia", datetime: "18:30" },
      { glyph: glyphs[2], title: "Aperitiu", datetime: "19:30" },
      { glyph: glyphs[3], title: "Sopar", datetime: "20:30" },
      { glyph: glyphs[4], title: "Festa", datetime: "22:30" },
    ];

    return (
      <Floor className="ScheduleFloor flow">
        <div className="flow">
          <h2>Horaris.</h2>
        </div>
        <ul className="Schedule" ref={(el) => (this.dom.schedule = el)}>
          {schedule.map((item, i) => (
            <li key={i}>
              <img
                className="glyph"
                src={item.glyph}
                alt=""
                ref={(el) => this.dom.glyphs.push(el)}
              />
              <div ref={(el) => this.dom.labels.push(el)}>
                <h4>{item.title}</h4>
                <time dateTime={item.datetime}>{item.datetime}h.</time>
              </div>
            </li>
          ))}
        </ul>
      </Floor>
    );
  }
}
export default ScheduleFloor;
