import React from "react";
import Floor from "../../atoms/Floor";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import "./DateFloor.scss";

const screenVideoUrl = new URL(`./phone-screen.mp4`, import.meta.url);
const googleCalendarUrl =
  "https://www.google.com/calendar/render?action=TEMPLATE&text=Boda+Gerard+i+Anna&details=https%3A%2F%2Fbenvingutsalafesta.com&location=Masia+El+Foll%C3%B3%2C+Tagamanent+https%3A%2F%2Fgoo.gl%2Fmaps%2FJDMuTy9uqM2G6MM47&dates=20220702T160000Z%2F20220703T000000Z";

const icalUrl = new URL(`/src/assets/boda-ga.ics`, import.meta.url);

class DateFloor extends React.Component {
  constructor(props) {
    super(props);

    this.dom = {};
  }

  componentDidMount() {
    // console.log("00", this.dom.root);
    gsap
      .timeline({
        ease: "none",
        scrollTrigger: {
          trigger: this.dom.root,
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      })
      .add([
        gsap
          .timeline()
          .fromTo(
            [this.dom.phone],
            { yPercent: 10 },
            { yPercent: 0, ease: "Power4.easeIn", duration: 1 }
          )
          .to([this.dom.phone], {
            yPercent: -5,
            ease: "Power4.easeOut",
            duration: 1,
          }),
        gsap.fromTo(
          [this.dom.dec1],
          { yPercent: -7 },
          { yPercent: 7, ease: "Power4.easeInOut", duration: 2 }
        ),
        gsap.fromTo(
          [this.dom.dec2],
          { yPercent: 10 },
          { yPercent: -10, ease: "Power4.easeInOut", duration: 2 }
        ),
        gsap.fromTo(
          [this.dom.dec3],
          { yPercent: -2 },
          { yPercent: 2, ease: "Power4.easeInOut", duration: 2 }
        ),
        gsap.fromTo(
          [this.dom.dec4],
          { yPercent: 5 },
          { yPercent: -5, ease: "Power4.easeInOut", duration: 2 }
        ),
      ]);
  }

  render() {
    return (
      <Floor
        className="DateFloor flow"
        forwardRef={(root) => (this.dom.root = root)}
      >
        <div className="flow">
          <h2>Guarda’t la data.</h2>
          <p>
            Ens casem el 02 de Juliol de 2022 i será una cerimònia de tarda.
          </p>
        </div>

        <div className="DateFloor__decs">
          <div ref={(el) => (this.dom.dec1 = el)}></div>
          <div ref={(el) => (this.dom.dec2 = el)}></div>
          <div ref={(el) => (this.dom.dec3 = el)}></div>
          <div ref={(el) => (this.dom.dec4 = el)}></div>
        </div>
        <div className="DateFloor__phone">
          <div className="Iphone" ref={(el) => (this.dom.phone = el)}>
            <video muted playsInline autoPlay src={screenVideoUrl}></video>
          </div>
        </div>
        {/* <div>
          <a href="#0" className="btn">
            Afegir al calendari
          </a>
        </div> */}
        <div className="flow">
          <h3>Afegir al calendari:</h3>
          <a href={googleCalendarUrl} className="btn" target="_blank">
            Google Calendar
          </a>
          <a href={icalUrl} className="btn">
            iCal
          </a>
        </div>
      </Floor>
    );
  }
}
export default DateFloor;
